@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: green;
}

.btn {
  @apply bg-white text-black text-center text-2xl rounded-lg p-5 uppercase;
  box-shadow: 0 0.5rem #bbb;
}

.btn:active {
  box-shadow: 0 0.3rem #bbb;
  transform: translateY(0.1rem);
}

.content__container {
  margin: 0 2rem 5rem 2rem;
}

.nav__container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin: 2rem;

  display: flex;
  justify-content: space-between;
}
